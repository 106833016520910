import styled from 'styled-components'

export const BoxColor = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
  margin-bottom: 50px;

  @media (min-width: 1024px) {
    margin-top: 63px;
  }

  &.blue {
    background-color: #047380;
    padding: 50px 0;

    .MuiGrid-container {
      @media (min-width: 1024px) {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    p,
    .color-title {
      color: white !important;
    }

    a {
      color: white !important;
      border-color: white !important;

      &:hover {
        background-color: #ebebeb4f;
      }
    }
  }

  .MuiGrid-item {
    &.image-card {
      @media (min-width: 768px) {
        margin: 10px auto 0 auto;
      }
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }

    a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      @media (min-width: 768px) {
        width: 20%;
      }
    }

    p {
      font-family: Signika !important;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #444444;
    }
  }

  .color-title {
    color: #302a38;
    font-weight: 600;
  }
`
