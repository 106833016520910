import React from 'react'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { graphql } from 'gatsby'
import { BoxColor } from './styled'
import Title from 'site/src/components/Title'
import { Button } from '@root/../atomic'
import { Container, Grid } from '@material-ui/core'
import * as Scroll from 'react-scroll'

export default props => {
  // const [expanded, setExpanded] = useState(false)
  const { metadata } = props.data.cosmicjsLandingPages

  // const handleChange = panel => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false)
  // }

  React.useEffect(() => {
    const search = props.location.search.replace('?slug=', '')
    console.log('window.location.search', props)
    // setTimeout(() => {
    scrollToField(search)
    // }, [500])
  }, [props.location.search])

  // const scrollToElement = elemento => {
  //   const el = document.querySelector(`#${elemento}`)

  //   el && el.scrollIntoView()

  //   window.scroll(0, window.scrollY - 150)
  //   // console.log('window.location.search', window.scrollY)
  // }

  const scrollToField = name => {
    const scroller = Scroll.scroller
    scroller.scrollTo(name, {
      smooth: true,
      offset: -150
    })
  }

  return (
    <IndexLayout location={props.location}>
      <Container maxWidth="md">
        <TitleWithBreadcrumbRow
          addtionalDictionary={{
            '/outrosServicos': metadata.title
          }}
        />

        <Title className="newLayout">Outros Serviços</Title>
      </Container>

      {metadata?.servicos_oferecidos.map((item, index) => {
        const id = item.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .split(' ')
          .join('-')
          .toLowerCase()

        return (
          <BoxColor id={id} className={index % 2 ? 'blue' : 'white'}>
            <Container maxWidth="md">
              <Grid container spacing={4}>
                <Grid item className="image-card" xs={12} sm={8} md={5}>
                  <img src={item.image.imgix_url} />
                </Grid>
                <Grid item sm={12} md={7}>
                  <Title className="color-title" noLine variant="h5">
                    {item.title}
                  </Title>
                  <p>{item.content}</p>
                  <Button
                    to={item.link}
                    external={item.link.substring(0, '1') !== '/'}
                    kind={'secundary'}
                  >
                    Confira
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </BoxColor>
        )
      })}
    </IndexLayout>
  )
}

export const query = graphql`
  query OutrosServicos {
    cosmicjsLandingPages(slug: { eq: "outros-servicos" }) {
      id
      metadata {
        servicos_oferecidos {
          image {
            url
            imgix_url
          }
          title
          content
          link
        }
      }
    }
  }
`
